<template>
  <div class="container">
    <el-divider content-position="left">我們的願景</el-divider>
    <div class="vision">
      <p class="purpose">
        願有一天，世界所有的人們 <br />
        不論出身、年紀、區域 <br />
        都能擁有知識學習與分享的權利 <br />
        One Day, All People
      </p>
      <p class="description">
        教育是改變命運的基石；是挹注人文素養的泉源<br />
        對於飢餓問題，貧窮是主要原因；孩子教育，是能徹底改善貧窮最有效果的過程。<br />
        對於資源匱乏，師資難求的區域；遠距教學，是快速提升教學品質與資源的方法。<br />
        對於知識傳授，乃至於知識分享；網路平台，可做為知識交換與知識傳承的媒介。<br />
        對於師生交流，延伸到組織運作；任務管理，可以是最有效簡化行政工作的工具。<br />
        喬米應用數據希望成為地球上最以顧客為中心的公司，建立一個人們可以終身共學的環境，並具備最佳的學習體驗。<br />
        該願景強調了對顧客的關注，以及提供廣泛知識學習與分享的目標。
      </p>
    </div>
    <el-divider content-position="left">組織起源</el-divider>
    <div class="origin">
      <div class="description">
        ChargeMe，全名「ChargeMe (喬米聯絡簿)」，
        是一個網路教學工具平台，上線於2023/09/20， 是由ChargeMe Applied
        Data(喬米應用數據)一家台灣公司所開發，
        它的名字是從耳機充電提示音ChargeMe而來，中文名字取其發音「喬米」，
        主要訴求為讓老師“聯絡簿”將知識傳播的數位學習工具而得其名「ChargeMe喬米聯絡簿」。
        <br /><br />
        它可以將老師、學生、家長圈成一個社群，用來規劃、追蹤、回饋、討論、公告、儲存和管理項目功能。
        <br /><br />
        ChargeMe Applied
        Data(喬米應用數據)，致力於解決「教育不平等」、「更好的教育環境與資源」、「更普及的教育」、「更豐富生命的分享與學習」等，學習與教學雙方面，在各社會層次的問題。承繼聯合國永續發展目標，<br />
        <br />
        <span class="SDG">
          SDG 1、SDG 2、SDG 4的世界永續方針，希望能為世界人民教育做出更多貢獻。
          <br />

          <br />(SDG 1：No Poverty 在全世界消除一切形式的貧困) <br />(SDG
          2：Zero Hunger 消除飢餓，實現糧食安全，改善營養狀況和促進永續農業)
          <br />(SDG 4：Quality Education
          確保包容和公平的優質教育，讓全民終身享有學習機會)</span
        >
      </div>
    </div>
    <el-divider content-position="left">我們的使命</el-divider>
    <div class="mission">
      <div class="description">
        ChargeMe的使命是透過建立網路資訊工具，強化教育對於世界永續的正面影響力<br />
        <br />
        期望做到: <br />
        消除貧窮 <br />
        消除飢餓 <br />
        消除師資不足<br />
        提供足夠完善的學習環境 <br />
        提供教、學適切需求的媒合<br />
        提供生活化的、輕鬆無負擔的學習文化<br />
        提供能透過知識分享為世界人們做出貢獻的喜悅<br />
        提供終身學習與貢獻的環境<br />
      </div>
    </div>
    <el-divider content-position="left">我們是誰</el-divider>
    <div class="whoAreUs">
      <div class="description">
        ChargeMe喬米應用數據，是一間公司，是一個團隊，有資訊開發團隊，有市場應用與推廣團隊，是一群來自不統領域的專業優質人才，因著共同的願景聚集，因著共享的核心價值而成為夥伴，一同發揮「用生命影響生命」的影響力。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.log_PageInfo("AboutUs", "10");
  },
};
</script>

<style lang="scss" scoped>
.vision,
.origin,
.mission,
.whoAreUs {
  text-align: left;

  .purpose {
    font-size: large;
    color: #c96d6a;
    font-weight: 600;
    line-height: 2;
    margin-bottom: 1rem;
  }
  .description {
    font-size: xx-small;
    line-height: 1.5;
    .SDG {
      font-weight: 600;
    }
  }
}
</style>
